import React from 'react'
import t from 'prop-types'

import styles from './cta.module.css'

import useImage from '../../hooks/useImage'
import Img from 'gatsby-image'

import Button from '../Button/Button'

const Cta = ({ backgroundImageName, objectPosition, title, text, buttonText }) => {
  return (
    <div className={ styles.Wrapper } >
      <Img fluid={ useImage(`${ backgroundImageName }`) } style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '-10' }} imgStyle={{ objectPosition: objectPosition }} />
      <div>
        <div>{ title }</div>
        <p>{ text }</p>
        <Button modal lg style={{ maxWidth: '300px' }} >{buttonText}</Button>
      </div>
    </div>
  )
}

Cta.propTypes = {
  backgroundImageName: t.string,
  objectPosition: t.string,
  title: t.any,
  text: t.string,
  buttonText: t.string,
}

Cta.defaultProps = {
  backgroundImageName: 'hero',
  objectPosition: 'center center',
  title: <h1>Lorem Ipsum</h1>,
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  buttonText: 'Lorem Ipsum',
}

export default Cta
