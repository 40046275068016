import t from 'prop-types'

import { useStaticQuery, graphql } from "gatsby"

function useImage ( name ) {

  const data = useStaticQuery(graphql`
    query allImages {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: ""}}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const edges = data.allFile.edges

  for (let i = 0; i < edges.length; i++) {
    if( edges[i].node.name === name ) {
      return edges[i].node.childImageSharp.fluid
    }
  }

  return null
}

useImage.propTypes = {
  name: t.string,
}

export default useImage
