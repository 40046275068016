import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from "../hoc/Layout/Layout"
import MainWrapper from '../hoc/MainWrapper/MainWrapper'

import SEO from "../components/seo"

import BenefitsCard from '../components/BenefitsCard/BenefitsCard'
import BlockContent from '../components/BlockContent/BlockContent'
import Button from '../components/Button/Button'
import CardHolder from '../components/CardHolder/CardHolder'
import CardGrid from '../components/CardGrid/CardGrid'
import Carousel from '../components/Carousel/Carousel'
import Cta from '../components/Cta/Cta'
import DashTitle from '../components/DashTitle/DashTitle'
import Hero from '../components/Hero/Hero'
import LinkCard from '../components/LinkCard/LinkCard'
import TestimonialCard from '../components/TestimonialCard/TestimonialCard'

const IndexPage = () => {

  const contentfulData = useStaticQuery(graphql`
    query allIndexPageData {
      allContentfulHero {
        edges {
          node {
            buttonText
            title
            text
          }
        }
      }

      allContentfulDashTitle {
        edges {
          node {
            title
            subText {
              json
            }
          }
        }
      }

      allContentfulBlockContent {
        nodes {
          title
          text
          buttonText
        }
      }

      allContentfulCta(sort: {fields: order}) {
        edges {
          node {
            text
            buttonText
            title {
              json
              content {
                content {
                  value
                }
              }
            }
          }
        }
      }

      allContentfulTestimonialCard(sort: {fields: order}) {
        nodes {
          text {
            text
          }
          businessLink
          instagramHandle
          name
          testimonialPicture {
            desktop: fixed(width: 96, height: 96, quality: 100) {
              ...GatsbyContentfulFixed
            }
            mobile: fixed(width: 80, height: 80, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }

      allContentfulNewsCard(sort: {fields: order}) {
        edges {
          node {
            newsLink
            header
            paperLogo {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  function transformContentfulHeader ( data ) {
    const arrayOfContent = data.map((child, index) => {
      if( index < data.length - 1 ) {
        return <React.Fragment key={ index } >{ child.content[0].value.replace(/\s/g, ' ') }<br className='HiddenMobile' /></React.Fragment>
      }
      return <React.Fragment key={ index } >{ child.content[0].value.replace(/\s/g, ' ') }</React.Fragment>
    })

    return arrayOfContent
  }

  const transformContentfulData = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <>{ children }<br className='HiddenMobile' /></>,
    },
    renderText: text => text.replace(/\s/g, ' ').replace('\n', <br className='HiddenMobile' />)
  }

  // Generates testimonial cards based on Contentful CMS
  const allTestimonialCards = () => {
    const cards = []

    contentfulData.allContentfulTestimonialCard.nodes.map((node, index) => {
      const card = <TestimonialCard
        photo={ node.testimonialPicture }
        name={ node.name }
        businessLink={ node.businessLink }
        businessName={ node.instagramHandle }
        text={ node.text.text }
        key={ index }
      />

      return cards.push(card)
    })

    return cards
  }

  // Generates link cards based on Contentful CMS
  const allLinkCards = () => {
    const cards = []

    contentfulData.allContentfulNewsCard.edges.map((node, index) => {
      const card = <LinkCard
        icon={ node.node.paperLogo }
        header={ node.node.header }
        link={ node.node.newsLink }
        key={ index }
      />

      return cards.push(card)
    })

    return cards
  }

  return(
    <MainWrapper>
      <SEO title="Home" />
      <Hero
        title={ contentfulData.allContentfulHero.edges[0].node.title }
        text={ contentfulData.allContentfulHero.edges[0].node.text }
        buttonText={ contentfulData.allContentfulHero.edges[0].node.buttonText }
      />
      <Layout reference='quem-somos' innerContainer={{ paddingBottom: '0' }} >
        <DashTitle
          subText={ documentToReactComponents(contentfulData.allContentfulDashTitle.edges[0].node.subText.json, transformContentfulData) } >
          { contentfulData.allContentfulDashTitle.edges[0].node.title }
        </DashTitle>
      </Layout>
      <Layout>
        <BlockContent
          firstChild = {
            <CardGrid
              cards= {[
                {
                  image: 'bebidas-e-sucos',
                  children: 'Bebidas e Sucos',
                },
                {
                  image: 'doces-e-bolos',
                  children: 'Doces e Bolos',
                },
                {
                  image: 'sorvetes-e-acai',
                  children: 'Sorvetes e Açaí',
                },
                {
                  image: 'lanchonete-e-salgados',
                  children: 'Lanchonetes e Salgados',
                },
                {
                  image: 'carnes-e-peixes',
                  children: 'Carnes e Peixes',
                },
                {
                  image: 'comida-japonesa',
                  children: 'Comida Japonesa',
                },
                {
                  image: 'saladas-e-vegetarianas',
                  children: 'Saladas e Vegetariana',
                },
                {
                  image: 'italiana',
                  children: 'Italiana',
                },
              ]}
            />
          }

          secondChild = {
            <>
              <h2 style={{ marginBottom: '1.5rem' }} >{ contentfulData.allContentfulBlockContent.nodes[0].title }</h2>
              <p style={{ marginBottom: '3.5rem' }} >{ contentfulData.allContentfulBlockContent.nodes[0].text }</p>
              <Button lg modal >{ contentfulData.allContentfulBlockContent.nodes[0].buttonText }</Button>
            </>
          }
        />
      </Layout>
      {/* Not appliable yet, to be develop in the future */}
      {/* <Layout>
        <BlockContent
          firstChild = {
            <>
              <h3 style={{ marginBottom: '1.5rem' }} >Lorem ipsum dolor sit amet</h3>
              <p style={{ marginBottom: '3.5rem' }} >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit</p>
              <Button lg modal />
            </>
          }

          secondChild = {
            <CardGrid />
          }

          mobileInvert={ true }
        />
      </Layout> */}
      <Layout reference='beneficios' >
        <h3 style={{ marginBottom: '5rem', textAlign: 'center' }} >Benefícios para o seu negócio</h3>
        <CardHolder extraStyling={{ marginBottom: '3rem' }} >
          <BenefitsCard
            icon= 'tudo-pronto-para-seu-delivery'
            name='Tudo pronto para seu delivery'
            text='Wi-fi, pontos de energia, água e gás com toda a infra-estrutura necessária para te dar mais rapidez e menos preocupações.'
          />
          <BenefitsCard
            icon= 'flexibilidade-e-liberdade'
            name='Flexibilidade e Liberdade'
            text='Assine a sua cozinha por quanto tempo quiser, mude quando quiser e tenha mais liberdade. Sem burocracias e sem fiador.'
          />
          <BenefitsCard
            icon= 'limpeza-do-ambiente'
            name='Limpeza do Ambiente'
            text='Fazemos a limpeza geral do ambiente comum, coleta de lixo, dedetização e controle de pragas.'
          />
        </CardHolder>
        <CardHolder>
          <BenefitsCard
            icon= 'reparos-e-manutencao'
            name='Reparos e Manutenção'
            text='Um imóvel pode dar muita dor de cabeça. Deu algum problema ou precisa de ajuda no espaço? Nós resolvemos.'
          />
          <BenefitsCard
            icon= 'seguranca'
            name='Segurança 24/7'
            text='Sistema de segurança, central de alarmes e monitoramento para proteger sua equipe e equipamentos.'
          />
          <BenefitsCard
            icon= 'investimento-baixo'
            name='Investimento e custos reduzidos'
            text='Os valores das nossas assinaturas são menores que os de aluguéis tradicionais e ainda oferecemos diversos serviços inclusos.'
          />
        </CardHolder>
      </Layout>
      <Cta
        backgroundImageName='cta-image-1-compressed'
        objectPosition='center 45%'
        title={ <h3>{ transformContentfulHeader(contentfulData.allContentfulCta.edges[0].node.title.content) }</h3> }
        text={ contentfulData.allContentfulCta.edges[0].node.text }
        buttonText={ contentfulData.allContentfulCta.edges[0].node.buttonText }
      />
      {/* Not appliable yet, when more kitchens get released this will be used */}
      {/* <Layout>
        <DashTitle>Conheça nossos espaços</DashTitle>
        <CardHolder>
          <KitchenCard
            kitchenTitle='Itapuã - Vila Velha'
            kitchenDescription='Cozinhas disponíveis para assinatura'
          />
          <KitchenCard
            kitchenTitle='Vila Velha'
            kitchenDescription='Em breve'
          />
          <KitchenCard
            kitchenTitle='Vitória'
            kitchenDescription='Em breve'
          />
        </CardHolder>
      </Layout> */}
      <Layout reference='clientes'>
        <h3 style={{ marginBottom: '4rem', textAlign: 'center' }} >O que os nossos clientes dizem por aí</h3>
        <Carousel
          content={allTestimonialCards()}
        />
      </Layout>
      <Cta
        backgroundImageName='cta-image-2-compressed'
        objectPosition='center 70%'
        title={ documentToReactComponents(contentfulData.allContentfulCta.edges[1].node.title.json, transformContentfulData) }
        text={ contentfulData.allContentfulCta.edges[1].node.text }
        buttonText={ contentfulData.allContentfulCta.edges[1].node.buttonText }
      />
      <Layout>
        <DashTitle
          subText='Reinventamos a forma de alugar cozinhas, entenda por que somos diferentes.'
        >O que a mídia está dizendo</DashTitle>
        <CardHolder extraStyling={{ marginTop: '3rem' }} >
          { allLinkCards() }
        </CardHolder>
      </Layout>
    </MainWrapper>
  )
}

export default IndexPage
