import React from 'react'
import t from 'prop-types'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../../images/icons/selection.json'

import styles from './simpleCard.module.css'

const SimpleCard = ({ image, children }) => {

  return (
    <div className={ styles.Wrapper } >
      <IcomoonReact color='var(--blue)' iconSet={ iconSet } icon={ image } alt={ `Ícone ` + children } />
      <span>{ children }</span>
    </div>
  )

}

SimpleCard.propTypes = {
  image: t.string,
  children: t.string,
}

SimpleCard.defaultProps = {
  image: 'saladas-e-vegetarianas',
  children: 'Lorem Ipsum'
}

export default SimpleCard
