import React, { useState, useEffect } from 'react'
import t from 'prop-types'

import Img from 'gatsby-image'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import styles from './testimonialCard.module.css'

const TestimonialCard = ({ photo, name, businessLink, businessName, text, ...rest }) => {

  const windowDimensions = useWindowDimensions();
  const [ mobile, setMobile ] = useState(false)

  useEffect(() => {
    if(windowDimensions.width <= 1024 ) {
      return setMobile(true)
    }
    return setMobile(false)
  }, [windowDimensions.width])

  return (
    <div className={ styles.Wrapper } {...rest} >
      <div className={ styles.Photo } >
        <Img fixed={ mobile ? photo.mobile : photo.desktop } imgStyle={{ borderRadius: '100%' }} alt={ name } />
      </div>
      <h4>{ name }</h4>
      <a href={ businessLink } target='_blank' rel='noopener noreferrer' >{ businessName }</a>
      <p>"{ text }"</p>
    </div>
  )
}

TestimonialCard.propTypes = {
  photo: t.any,
  name: t.string,
  businessLink: t.string,
  businessName: t.string,
  text: t.string,
}

TestimonialCard.defaultProps = {
  photo: 'breno-rosi',
  name: 'Rodrigo Lomeu',
  businessLink: 'https://www.instagram.com/acaipraiavv/',
  businessName: 'Kitchenfy',
  text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."',
}

export default TestimonialCard
