import React from 'react'
import t from 'prop-types'

import styles from './cardHolder.module.css'

import TestimonialCard from '../TestimonialCard/TestimonialCard'

const CardHolder = ({ children, extraStyling }) => {
  return (
    <div className={ styles.Wrapper } style={{ ...extraStyling }} >
      { children }
    </div>
  )
}

CardHolder.propTypes = {
  children: t.any,
  extraStyling: t.any,
}

CardHolder.defaultProps = {
  children: <><TestimonialCard /><TestimonialCard /><TestimonialCard /></>,
  extraStyling: '',
}

export default CardHolder
