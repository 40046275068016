import React from 'react'
import t from 'prop-types'

import styles from './linkCard.module.css'

import Img from 'gatsby-image'

const LinkCard = ({ link, icon, header }) => {
  return (
    <a href={ link } className={ styles.Wrapper } rel="noreferrer" target='_blank' >
      <Img fluid={ icon.fluid } className={ styles.Icon } alt='Logo Jornal' />
      <p>{ header }</p>
    </a>
  )
}

LinkCard.propTypes = {
  link: t.string,
  icon: t.object,
  header: t.string,
}

LinkCard.defaultProps = {
  link: 'https://g1.globo.com/economia/tecnologia/noticia/2020/03/02/dark-kitchens-o-que-sao-as-cozinhas-fantasma-que-so-existem-em-apps-de-comida.ghtml',
  icon: 'g1-logo',
  header: "'Dark kitchens': o que são as 'cozinhas fantasma', que só existem em apps de comida"
}

export default LinkCard
