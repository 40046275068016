import React from 'react'
import t from 'prop-types'

import styles from './blockContent.module.css'

const BlockContent = ({ firstChild, secondChild, mobileInvert }) => {
  return (
    <div className={ styles.Wrapper } >
      <span className={ mobileInvert ? styles.Order2 : styles.Order1 } >
        { firstChild }
      </span>
      <aside className={ mobileInvert ? styles.Order1 : styles.Order2 } >
        { secondChild }
      </aside>
    </div>
  )
}

BlockContent.propTypes = {
  firstChild: t.object,
  secondChild: t.object,
  mobileInvert: t.bool,
}

BlockContent.defaultProps = {
  mobileInvert: false,
}

export default BlockContent
