import React from 'react'
import t from 'prop-types'

import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import styles from './hero.module.css'

import Layout from '../../hoc/Layout/Layout'

import Button from '../Button/Button'

const Hero = ({ title, text, buttonText }) => {

  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: {eq: "hero.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 2320, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout
      outerContainer = {{ height: '100vh' }}
      innerContainer = {{ height: '100%', padding: '0 1.2rem' }}
    >
      <div className={ styles.Wrapper } >
        <Img style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', zIndex: '-10', objectFit: 'cover' }} fluid={ data.heroImage.childImageSharp.fluid } />
        <h1>{ title }</h1>
        <p>{ text }</p>
        <Button lg modal >{ buttonText }</Button>
      </div>
    </Layout>
  )
}

Hero.propTypes = {
  title: t.string,
  text: t.string,
  buttonText: t.string,
}

Hero.defaultProps = {
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
}

export default Hero
