import React, { useState, useEffect } from 'react'
import t from 'prop-types'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../images/icons/selection.json'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import styles from './carousel.module.css'

const Carousel = ({ content, displayNumber, contentWrapperStyling }) => {

  const [displayValue, setDisplayValue] = useState(0)
  const [mobileDisplayValue, setMobileDisplayValue] = useState(0)

  const windowDimensions = useWindowDimensions()
  const [ mobile, setMobile ] = useState(false)

  useEffect(() => {
    if(windowDimensions.width <= 1024 ) {
      return setMobile(true)
    }
    return setMobile(false)
  }, [windowDimensions.width])

  const singleContentArray = []

  function returnContent () {

    let indicator = 0
    const fullArray = []

    for( let i = 0; i < Math.ceil(content.length / displayNumber); i++ ) {
      const contentArray = []

      do {
        contentArray.push(<div key={ indicator }>{ content[indicator] }</div>)
        singleContentArray.push(<div key={ indicator }>{ content[indicator] }</div>)
        indicator++
      } while( indicator < (displayNumber * (i + 1)) && indicator < content.length )

      fullArray.push(contentArray)
    }

    return fullArray
  }

  const contentDisplayed = returnContent()

  function previousSlide () {
    if(mobile) {
      if(mobileDisplayValue - 1 < 0) {
        setMobileDisplayValue(singleContentArray.length - 1)
      } else {
        setMobileDisplayValue(mobileDisplayValue - 1)
      }
    } else {
      if(displayValue - 1 < 0) {
        setDisplayValue(contentDisplayed.length - 1)
      } else {
        setDisplayValue(displayValue - 1)
      }
    }
  }

  function nextSlide () {
    if(mobile) {
      if(mobileDisplayValue + 1 > singleContentArray.length - 1) {
        setMobileDisplayValue(0)
      } else {
        setMobileDisplayValue(mobileDisplayValue + 1)
      }
    } else {
      if(displayValue + 1 > contentDisplayed.length - 1) {
        setDisplayValue(0)
      } else {
        setDisplayValue(displayValue + 1)
      }
    }
  }

  return (
    <div className={ styles.Wrapper } >
      <button onClick={ () => previousSlide() } name='Anterior' >
        <span style={{ fontSize: '0' }} >Anterior</span>
        <IcomoonReact size='2rem' color='white' iconSet={ iconSet } icon='arrow-down' alt='Anterior' style={{ transform: 'rotate(90deg)' }} />
      </button>
      <div className={ styles.ContentWrapper } style={ contentWrapperStyling } >
        { mobile ? singleContentArray[mobileDisplayValue] : contentDisplayed[displayValue] }
      </div>
      <button onClick={ () => nextSlide() } name='Próxima' >
        <span style={{ fontSize: '0' }} >Próxima</span>
        <IcomoonReact size='2rem' color='white' iconSet={ iconSet } icon='arrow-down' alt='Próxima' style={{ transform: 'rotate(-90deg)' }} />
      </button>
    </div>
  )
}

Carousel.propTypes = {
  content: t.array,
  displayNumber: t.number,
  contentWrapperStyling: t.object,
}

Carousel.defaultProps = {
  content: [
    <p>Teste 1</p>,
    <p>Teste 2</p>,
    <p>Teste 3</p>,
    <p>Teste 4</p>,
    <p>Teste 5</p>,
    <p>Teste 6</p>,
    <p>Teste 7</p>,
    <p>Teste 8</p>,
    <p>Teste 9</p>,
    <p>Teste 10</p>,
    <p>Teste 11</p>,
    <p>Teste 12</p>,
    <p>Teste 13</p>,
  ],
  displayNumber: 3,
  contentWrapperStyling: {}
}

export default Carousel
