import React from 'react'
import t from 'prop-types'

import styles from './cardGrid.module.css'

import SimpleCard from './SimpleCard/SimpleCard'

const CardGrid = ({ cards }) => {

  return (
    <div className={ styles.Wrapper } >
      <div className={ styles.Grid } >
        {cards.map((card, index) => {
          return <SimpleCard image={ card.image } key={ index } >{ card.children }</SimpleCard>
        })}
      </div>
    </div>
  )
}

CardGrid.propTypes = {
  cards: t.arrayOf(t.objectOf(t.any, t.string))
}

CardGrid.defaultProps = {
  cards: [
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
    {
      image: 'saladas-e-vegetarianas',
      children: 'Lorem Ipsum',
    },
  ]
}

export default CardGrid
