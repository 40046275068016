import React from 'react'
import t from 'prop-types'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../images/icons/selection.json'

import styles from './benefitsCard.module.css'

const BenefitsCard = ({ icon, name, text, ...rest }) => {
  return (
    <div className={ styles.Wrapper } {...rest} >
      <div className={ styles.Photo } >
        <IcomoonReact color='var(--orange)' iconSet={ iconSet } icon={ icon } alt={ `Ícone ` + name } />
      </div>
      <h4>{ name }</h4>
      <p>{ text }</p>
    </div>
  )
}

BenefitsCard.propTypes = {
  icon: t.string,
  name: t.string,
  text: t.string,
}

BenefitsCard.defaultProps = {
  icon: 'tudo-pronto-para-seu-delivery',
  name: 'Lorem Ipsum',
  text: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."'
}

export default BenefitsCard
